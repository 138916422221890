import { loadStripe } from "@stripe/stripe-js";

// Create a Checkout Session
export async function initializeCheckout() {
  const stripe = await loadStripe(window.ENV.STRIPE_PUBLIC_KEY);

  const fetchClientSecret = async () => {
    const response = await fetch("/create-checkout-session", {
      method: "POST",
      credentials: "same-origin",
    });
    if (!response.ok) {
      const data = await response.json();
      console.error(
        `Error response initializing session: ${data.error}`,
        response,
        data,
      );
      return null;
    }
    const { clientSecret } = await response.json();
    return clientSecret;
  };
  const clientSecret = await fetchClientSecret();
  if (!clientSecret) {
    // @ts-ignore
    document.getElementById("checkout").innerHTML = "<p>There was an error</p>";
    return;
  }
  const dummyFetchClientSecret = async () => {
    return clientSecret;
  };

  const checkout = await stripe?.initEmbeddedCheckout({
    fetchClientSecret: dummyFetchClientSecret,
  });

  // Mount Checkout
  checkout?.mount("#checkout");
}
