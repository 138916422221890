import Cookies from "js-cookie";
import htmx from "htmx.org/dist/htmx.esm";

import {
	addProductToCart,
	fillCart,
	getIsLiveOrder,
	getShippingMethod,
	resetCart,
	setIsLiveOrder,
	setShippingMethod,
	updateCartPreview,
} from "../components/cart";
import { initializeCheckout } from "../components/checkout";
import { sendEvent } from "../components/event";
import { validateCheckoutReturn } from "../components/return";

window.addProductToCart = addProductToCart;
window.getCookie = Cookies.get;
window.getIsLiveOrder = getIsLiveOrder;
window.getShippingMethod = getShippingMethod;
window.fillCart = fillCart;
window.initializeCheckout = initializeCheckout;
window.resetCart = resetCart;
window.sendEvent = sendEvent;
window.setIsLiveOrder = setIsLiveOrder;
window.setShippingMethod = setShippingMethod;
window.updateCartPreview = updateCartPreview;
window.validateCheckoutReturn = validateCheckoutReturn;

window.htmx = htmx;
